<template>
  <div class="carhistoricalstate">
    <el-card class="box-card father-card" shadow="never">
      <!-- 头部区域 -->
      <el-row class="btns">
        <el-form ref="form" :model="form" inline>
          <!-- 搜索框 -->
          <el-form-item label="车牌号码">
            <el-input
              v-model="form.carNum"
              placeholder="请输入车牌号码"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="车牌卡号">
            <el-input
              v-model="form.simPhone"
              placeholder="请输入卡号"
              clearable
            ></el-input>
          </el-form-item>
          <!-- 按钮区域 -->
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="search()"
              >搜索</el-button
            >
          </el-form-item>
        </el-form>
      </el-row>
      <!-- 表格区域 -->
      <el-row class="tab">
        <el-table
          ref="multipleTable"
          :data="tabData"
          tooltip-effect="dark"
          style="width: 100%"
          highlight-current-row
          stripe
          border
          :header-cell-style="rowClass"
        >
          <el-table-column
            prop="carNum"
            label="车牌号码"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="simPhone"
            label="sim卡号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="renewUserName"
            label="续费人"
            align="center"
          ></el-table-column>
          <!-- <el-table-column prop="renewFee" label="续费费用" align="center"></el-table-column> -->
          <el-table-column
            prop="validDate"
            label="有效期"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="renewDate"
            label="续费时间"
            align="center"
          ></el-table-column>
          <el-table-column prop="status" label="是否到期" align="center">
            <template slot-scope="{ row }">
              <span v-if="row.status == 1">已续费</span>
              <span class="red" v-else>已到期</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" min-width="200px">
            <template slot-scope="scope">
              <el-button size="mini" type="success" @click="look(scope.row)"
                >设备续费</el-button
              >
              <el-button size="mini" type="success" @click="simlook(scope.row)"
                >sim卡续费</el-button
              >
              <el-button size="mini" type="success" @click="lookDetail(scope.row)"
                >sim续费详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handlePageNumChange"
          :current-page="paging.PageNo"
          :page-sizes="[10, 15, 20, 50]"
          :page-size="paging.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </el-row>
      <!-- 机构详情弹出框 -->
      <el-dialog title="续费" :visible.sync="dialogVisible" width="30%">
        <el-form :rules="rules" :model="simForm" ref="simForm" label-width="100px">
          <el-form-item label="续费金额" prop="renewFee">
            <el-input
              oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')"
              v-model.number="simForm.renewFee"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="续费日期" prop="renewDate">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              v-model="simForm.renewDate"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="simForm.remark"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div style="text-align: right">
              <el-button type="primary" @click="submitForm('simForm')">提交</el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
      <!-- 续费详情 -->
      <el-dialog title="续费详情" :visible.sync="dialogVisibleDetails" width="70%">
        <el-table :data="simRecorDdata" style="width: 100%">
          <el-table-column prop="simPhone" label="sim卡号"> </el-table-column>
          <el-table-column prop="renewFee" label="续费金额"> </el-table-column>
          <el-table-column prop="renewDate" label="续费时间"> </el-table-column>
          <el-table-column prop="remark" label="备注"> </el-table-column>
        </el-table>
        <pagination v-show="simRecordPage.total>0" :total="simRecordPage.total" :page.sync="simRecordPage.current" :limit.sync="simRecordPage.size" @pagination="getSimRecond" />

      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import _ from 'lodash'
import Pagination from '@/components/Pagination'

export default {
  name: 'carhistoricalstate',
  components: {
    Pagination
  },
  data () {
    return {
      simRecordPage: {
        size: 10,
        current: 1,
        total: 0
      },
      simRecorDdata: [],
      // 设置不能选则当天之前的时间    写data()中
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      simForm: {
        renewFee: null,
        renewDate: null,
        remark: null
      },
      tabData: [], // 表格数据源
      form: { carNum: '' }, // 搜索表单
      total: 0, // 总数据条数
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      dialogVisible: false, // 控制添加对话框的显示与隐藏
      dialogVisibleDetails: false,
      selectForm: {}, // 选中的表单对象
      // is: true,
      addFormRules: {
        companyName: [{ required: true, message: '请输入机构名称', trigger: 'blur' }]
      }, // 添加表单校验规则
      rules: {
        renewFee: [
          { required: true, message: '请输入续费金额', trigger: 'blur' },
          { type: 'number', message: '续费金额必须为数字值' }
        ],
        renewDate: [
          //   { required: true, message: '请选择续费日期', trigger: 'change' },
          { required: true, message: '请选择续费日期', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    this.getAllCar()
  },
  methods: {
    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    /**
     * sim续费记录
     */
    async getSimRecond () {
      const { data: res } = await this.$http.post(
        '/pmService/deviceRenew/getSimRenewRecord',
        {
          size: this.simRecordPage.size,
          current: this.simRecordPage.current,
          carNum: this.selectForm.carNum,
          simPhone: this.selectForm.simPhone
        }
      )
      if (res.statusCode !== '200') return this.$message.error('获取失败')
      this.simRecorDdata = res.data.records
      this.simRecordPage.total = res.data.total
    },
    // 获取车辆历史状态
    async getAllCar () {
      const { data: res } = await this.$http.post(
        '/pmService/deviceRenew/getVehicleSimList',
        {
          size: this.paging.pageSize,
          current: this.paging.PageNo,
          ...this.form
          // status: 1
        }
      )
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取失败')
      this.tabData = res.data.records
      this.total = +res.data.total
    },
    // 点击搜索按钮触发
    async search () {
      this.paging.PageNo = 1
      this.getAllCar()
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getAllCar()
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      this.getAllCar()
    },
    // 点击查看详情按钮触发 海康年费续费
    look (v) {
      this.selectForm = v
      console.log(this.selectForm, 'this.selectForm')
      this.$confirm('是否进行续费?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.sure()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    /**
     * sim续费
     */
    simlook (row) {
      this.selectForm = row
      this.dialogVisible = true
      this.simForm.renewFee = null
      this.simForm.renewDate = null
      this.simForm.remark = null
      console.log('sim续费')
    },
    /**
     * 续费详情
     */
    lookDetail (row) {
      this.dialogVisibleDetails = true
      this.selectForm = row
      this.getSimRecond()
      console.log('续费详情')
    },

    // 点击详情确定框触发
    async sure () {
      // 进行编辑操作
      const obj = {
        carNum: this.selectForm.carNum,
        simPhone: this.selectForm.simPhone,
        renewUser: sessionStorage.getItem('userId')
      }
      //   console.log(obj, 'obj')
      const { data: res } = await this.$http.post(
        '/pmService/deviceRenew/submitRenew',
        obj
      )
      console.log(res)
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('修改失败')
      // 成功提示
      this.$message.success('修改成功')
      // 重新渲染数据
      this.getAllCar()
      this.orderDialogFormVisible = false
    },
    // sim续费提交
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const obj = {
            simPhone: this.selectForm.simPhone,
            renewUser: sessionStorage.getItem('userId'),
            renewDate: this.simForm.renewDate,
            renewFee: this.simForm.renewFee,
            remark: this.simForm.remark
          }
          const { data: res } = await this.$http.post(
            '/pmService/deviceRenew/submitSimRenew',
            obj
          )
          if (res.statusCode !== '200') return this.$message.error('修改失败')
          // 成功提示
          this.$message.success('修改成功')
          this.dialogVisible = false
          // 重新渲染数据
          this.getAllCar()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.carhistoricalstate {
  width: 100%;
  height: 100%;
  //隐藏滚动条
  ::-webkit-scrollbar {
    // display: none; /* Chrome Safari */
  }
  .father-card {
    .btns {
      margin-bottom: 10px;
    }
    .tab {
      /deep/ .el-table {
        margin-bottom: 20px;
        .el-table__row--striped td {
          background-color: #e4eaec !important;
        }
        .current-row > td {
          background-color: #66b1ff !important;
        }
      }
      .el-pagination {
        margin-bottom: 0.25rem;
        margin-right: 0.25rem;
        text-align: right;
      }
    }
    .add-dialog,
    .order-dialog {
      .dialog-Title {
        color: #0097fe;
        font-size: 20px;
        font-weight: 700;
      }
      /deep/ .el-form {
        .subtitle {
          color: #0097fe;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 20px;
          padding-left: 6px;
          border-left: 5px solid #0097fe;
        }
        .el-row {
          display: flex;
          .el-form-item {
            flex: 1;
            display: flex;
            .el-form-item__content {
              flex: 1;
              .el-input {
                width: 100%;
                .el-input__inner {
                  width: 100%;
                }
              }
              .el-select {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
.red {
  color: red;
}
.el-date-editor {
  width: 100%;
}
</style>
